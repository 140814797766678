import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { RequestModels_EnergyProfile_RequestModel } from '@monorepo-types/dc';
import { RequestModels_Usages_P4_MotivationsModel } from '@monorepo-types/dc';
import { ResponseDataSystem_Boolean } from '@monorepo-types/dc';
import { ResponseModels_EnergyProfile_ResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Usages_P4_MotivationsModel } from '@monorepo-types/dc';

import { request } from '../client';
import type { ApiRequestConfig } from '../client/types';

type GetEnergyProfile = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetEnergyProfile
 * Gets the energy profile.
 * @returns ResponseModels_EnergyProfile_ResponseModel If process went ok, body contains the energy profile.
 */
export function getEnergyProfile(
  { businessUnit, label, customerId, accountId }: GetEnergyProfile,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_EnergyProfile_ResponseModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/energyprofile`,
      errors: { 400: 'If backend returns an error or input is invalid.', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type StoreEnergyProfile = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  requestBody?: RequestModels_EnergyProfile_RequestModel;
};
/**
 * StoreEnergyProfile
 * Stores the energy profile.
 * @returns void
 */
export function storeEnergyProfile(
  { businessUnit, label, customerId, accountId, requestBody }: StoreEnergyProfile,
  requestConfig: ApiRequestConfig = {},
): Promise<void> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/energyprofile`,
      body: requestBody,
      errors: { 400: 'If backend returns an error or input is invalid.' },
    },
    requestConfig,
  );
}

type GetPrivacyConsent = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetPrivacyConsent
 * Gets the privacy consent.
 * @returns ResponseDataSystem_Boolean If process went ok, body contains the energy profile.
 */
export function getPrivacyConsent(
  { businessUnit, label, customerId, accountId }: GetPrivacyConsent,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseDataSystem_Boolean> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/consent`,
      errors: { 400: 'If backend returns an error or input is invalid.' },
    },
    requestConfig,
  );
}

type GivePrivacyConsent = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GivePrivacyConsent
 * Gives the privacy consent.
 * @returns void
 */
export function givePrivacyConsent(
  { businessUnit, label, customerId, accountId }: GivePrivacyConsent,
  requestConfig: ApiRequestConfig = {},
): Promise<void> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/consent`,
      errors: { 400: 'If backend returns an error or input is invalid.', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type RevokePrivacyConsent = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * RevokePrivacyConsent
 * Revokes the privacy consent.
 * @returns void
 */
export function revokePrivacyConsent(
  { businessUnit, label, customerId, accountId }: RevokePrivacyConsent,
  requestConfig: ApiRequestConfig = {},
): Promise<void> {
  return request(
    {
      method: 'DELETE',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/consent`,
      errors: { 400: 'If backend returns an error or input is invalid.' },
    },
    requestConfig,
  );
}

type GetMotivations = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetMotivations
 * Get the Motivations on why the users uses the insights app. the ExternalReference (Id) is generated in the frontend
 * @returns ResponseModels_Usages_P4_MotivationsModel Success
 */
export function getMotivations(
  { businessUnit, label, customerId, accountId }: GetMotivations,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Usages_P4_MotivationsModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/motivations`,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type StoreMotivations = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  requestBody?: RequestModels_Usages_P4_MotivationsModel;
};
/**
 * StoreMotivations
 * Get the Motivations on why the users uses the insights app. the ExternalReference (Id) is generated in the frontend
 * @returns void
 */
export function storeMotivations(
  { businessUnit, label, customerId, accountId, requestBody }: StoreMotivations,
  requestConfig: ApiRequestConfig = {},
): Promise<void> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/motivations`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}
