import { useEffect, useState, useCallback } from 'react';

import { App, AppState } from '@capacitor/app';
import { TokenResponse } from '@openid/appauth';
import { BehaviorSubject, timer, SchedulerLike } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import logger from '@common/log';

import { getAuthService } from '../service';

export const appIsActive$ = new BehaviorSubject<boolean>(true);

/**
 * useAuth hook
 *
 * To simulate a token that is about to expire:
 *   set the buffer option of `getValidToken()` and `token.isValid()` to e.g. `-3600 + 5`
 */
export function useAuth(scheduler: SchedulerLike | undefined = undefined) {
  const authService = getAuthService();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Refresh token helper
  const refreshToken = useCallback(
    async (showLoader: boolean) => {
      if (showLoader) {
        setIsRefreshing(true);
      }

      await authService
        .getValidToken()
        .catch(error => {
          logger.error('d07IXc', 'Token retrieval failed', error);
        })
        .finally(() => {
          setIsRefreshing(false);
        });
    },
    [authService],
  );

  // Listen to app state changes
  useEffect(() => {
    App.addListener('appStateChange', (state: AppState) => {
      appIsActive$.next(state?.isActive);
    });
  }, []);

  // Set isAuthenticated
  useEffect(() => {
    const subscription = authService.initComplete$
      .pipe(
        filter(isComplete => isComplete),
        switchMap(() => authService.isAuthenticated$),
      )
      .subscribe(isAuthenticated => {
        setIsAuthenticated(isAuthenticated);
        setIsLoaded(true);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [authService]);

  // Load user info
  useEffect(() => {
    const subscription = authService.initComplete$
      .pipe(
        filter(isComplete => isComplete),
        switchMap(() => {
          return authService.token$;
        }),
      )
      .subscribe((token: TokenResponse) => {
        if (!token) return;

        if (token.isValid()) {
          authService.loadUserInfo().catch(error => {
            logger.error('LaGd2Y', 'Load user info failed', error);
          });
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [authService]);

  // Refresh token on interval, when app is active
  useEffect(() => {
    const refreshInterval$ = new BehaviorSubject<number | undefined>(0);

    const tokenSubscription = authService.initComplete$
      .pipe(
        filter(isComplete => isComplete),
        switchMap(() => {
          return authService.token$;
        }),
      )
      .subscribe((token: TokenResponse) => {
        if (!token) return;

        if (token.isValid() && token.expiresIn) {
          refreshInterval$.next(token.expiresIn * 1000 * 0.9);
        }
      });

    const refreshIntervalSubscription = refreshInterval$
      .pipe(
        filter(interval => !!interval),
        switchMap(interval => {
          return timer(interval as number, scheduler);
        }),
      )
      .subscribe(() => {
        if (appIsActive$.getValue()) {
          refreshToken(false);
        }
      });

    return () => {
      tokenSubscription.unsubscribe();
      refreshIntervalSubscription.unsubscribe();
    };
  }, [authService, refreshToken, scheduler]);

  // If needed, refresh token on app resume
  useEffect(() => {
    const subscription = appIsActive$
      .pipe(
        distinctUntilChanged(),
        filter(isActive => isActive),
        switchMap(() => authService.initComplete$),
        filter(isComplete => isComplete),
        switchMap(() => authService.token$),
        filter(token => !!token),
      )
      .subscribe((token: TokenResponse) => {
        if (!token) return;

        if (!token.isValid()) {
          refreshToken(true);
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [authService, refreshToken]);

  return { isLoaded, isAuthenticated, isRefreshing };
}
