import { FC } from 'react';

import { I18nProvider, useTranslation } from '@i18n';
import { PageGrid, Heading, Stack, Text, Box, IconButton } from '@sparky';
import { CloseIcon } from '@sparky/icons';

interface Props {
  errorStatusCode: number;
  onClose?: () => void;
}

export const ErrorPage: FC<Props> = ({ errorStatusCode, onClose }) => (
  <I18nProvider dictionary={locale => import(`./content/${locale}.json`)}>
    <ErrorPageComponent errorStatusCode={errorStatusCode} onClose={onClose} />
  </I18nProvider>
);

const ErrorPageComponent: FC<Props> = ({ errorStatusCode, onClose }) => {
  const { t } = useTranslation();

  const key = errorStatusCode === 404 ? '404' : 'default';
  const title = t(`${key}.title`);
  const message = t(`${key}.message`);

  return (
    <PageGrid>
      <PageGrid.Item gridColumn={{ initial: '1/-1', md: '2/10' }}>
        <Box paddingY={{ initial: '8', md: '16' }}>
          <Stack gap="5">
            {onClose && (
              <Stack direction="column" alignX="end" alignY="center">
                <IconButton onClick={onClose} label="Close" size="regular">
                  <CloseIcon />
                </IconButton>
              </Stack>
            )}
            <Heading as="h1" size={{ initial: 'XL', md: '2XL' }}>
              {title}
            </Heading>
            <Text>{message}</Text>
          </Stack>
        </Box>
      </PageGrid.Item>
    </PageGrid>
  );
};

export default ErrorPage;
