import { FC, useContext } from 'react';

import { useTranslation } from '@i18n';
import { Heading, Stack, Text, TextLink } from '@sparky';
import { ChevronLeftIcon } from '@sparky/icons';

import { PrivacyConsentMachine, PrivacyConsentMachineActions } from './privacyConsentMachine';

const PrivacyContent: FC = () => {
  const { t } = useTranslation();
  const { send } = useContext(PrivacyConsentMachine);

  return (
    <Stack gap="6">
      <Stack gap="5">
        <TextLink
          onClick={() => send({ type: PrivacyConsentMachineActions.TO_PRIVACY_CONSENT })}
          emphasis="medium"
          leftIcon={<ChevronLeftIcon />}>
          {t('privacyStatement.backButtonText')}
        </TextLink>
        <Heading as="h1" size="S">
          {t('privacyStatement.title')}
        </Heading>
      </Stack>
      <Stack gap="4">
        <Stack gap="1">
          <Text weight="bold">{t('privacyStatement.advantages.title')}</Text>
          <Text>{t('privacyStatement.advantages.description')}</Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PrivacyContent;
