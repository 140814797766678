import { Preferences } from '@capacitor/preferences';

import { AppPreferences } from '@common/preferences';
import { Insights_BelgiumExternalMandates_ExternalMandateBe } from '@monorepo-types/dc';

export function isAllMandatesInactive(externalMandates: Array<Insights_BelgiumExternalMandates_ExternalMandateBe>) {
  return externalMandates?.every(
    mandateItem => mandateItem.status !== 'approved' && mandateItem.status !== 'requested',
  );
}

export function isAllMandatesApproved(externalMandates: Array<Insights_BelgiumExternalMandates_ExternalMandateBe>) {
  return externalMandates?.every(mandateItem => mandateItem.status === 'approved');
}

export function hasNotActivatedMandates(externalMandates: Array<Insights_BelgiumExternalMandates_ExternalMandateBe>) {
  return externalMandates?.some(mandateItem => mandateItem.status !== 'approved' && mandateItem.status !== 'requested');
}

export function isMandateIsNotApprovedOrRequested(
  eanCode: string,
  externalMandates: Array<Insights_BelgiumExternalMandates_ExternalMandateBe>,
) {
  return !!externalMandates?.find(
    mandateItem =>
      mandateItem.eanCode === eanCode && mandateItem.status !== 'approved' && mandateItem.status !== 'requested',
  );
}
export function isMandateNotActivated(
  eanCode: string,
  externalMandates: Array<Insights_BelgiumExternalMandates_ExternalMandateBe>,
) {
  return isMandateIsNotApprovedOrRequested(eanCode, externalMandates) && !isAllMandatesInactive(externalMandates);
}

async function partialMandateAccepted() {
  const { PARTIAL_MANDATE_MODE } = AppPreferences;

  if (typeof document === 'undefined') {
    return;
  }

  const url = new URL(document.URL);
  const itemParam = url.searchParams.get(PARTIAL_MANDATE_MODE);
  if (itemParam) {
    await Preferences.set({ key: PARTIAL_MANDATE_MODE, value: itemParam });
  }
  const { value } = await Preferences.get({ key: 'partialMandateMode' });
  return value === '1';
}

export const hasUnfinishedFluviusOnboarding = async (
  externalMandates: Insights_BelgiumExternalMandates_ExternalMandateBe[],
  isAllMandatesInactived: boolean,
  hasUnmappedMandates: boolean,
) => {
  const isPartialMandateAccepted = await partialMandateAccepted();
  const hasActiveMandate = hasNotActivatedMandates(externalMandates);

  const hasMandateData = !isAllMandatesInactived || hasUnmappedMandates;
  const hasNoActivatedButUnmappedMandates = isAllMandatesInactived && hasUnmappedMandates && !isPartialMandateAccepted;

  const hasActivateMandate = hasActiveMandate && !isPartialMandateAccepted;

  return !hasMandateData || hasNoActivatedButUnmappedMandates || hasActivateMandate;
};
