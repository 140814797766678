import { useEffect } from 'react';

import env from '@common/env';
import { useUsagesGetShortUrlAndExternalMandate } from '@dc/hooks';
import useDC from '@dc/useDC';

export function useSendGetShortUrlAndExternalMandate() {
  const externalMandate = useUsagesGetShortUrlAndExternalMandate();

  const { customerId, accountId, label, businessUnit } = useDC();

  useEffect(() => {
    if (!accountId || !customerId || !label || !businessUnit) return;
    externalMandate.send({
      customerId,
      accountId,
      label,
      businessUnit,
      returnUrlSuccess: env('EXTERNAL_MANDATE_RETURN_URL_SUCCESS') ?? null,
      returnUrlFailed: env('EXTERNAL_MANDATE_RETURN_URL_FAILED') ?? null,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, accountId, label, businessUnit]);

  return externalMandate;
}
