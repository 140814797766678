import { FC } from 'react';

import { NaturalScrollingArea } from '@custom-components/native/NaturalScrollingArea';
import SafeArea from '@custom-components/native/SafeArea';
import { Box, Stretch } from '@sparky';

type Props = {
  children: React.ReactNode;
};

const InsightsLayout: FC<Props> = ({ children }) => {
  return (
    <Stretch>
      <Box backgroundColor={'backgroundPrimary'}>
        <SafeArea top bottom>
          <NaturalScrollingArea>
            <Stretch>
              <Box paddingX="6">{children}</Box>
            </Stretch>
          </NaturalScrollingArea>
        </SafeArea>
      </Box>
    </Stretch>
  );
};

export default InsightsLayout;
