import { I18nProvider, useTranslation } from '@i18n';
import { NotificationBox } from '@sparky';

import { ValidationExceptions } from './types';

function getErrorMessageKey(errorMessageCode: string): string {
  const errorMessageKey = Object.keys(ValidationExceptions).find(
    key => ValidationExceptions[key as keyof typeof ValidationExceptions] === errorMessageCode,
  );

  return errorMessageKey || ValidationExceptions.DEFAULT;
}

export interface Props {
  errorMessageCode: string;
}

const DigitalCoreExceptionComponent: React.FC<Props> = ({ errorMessageCode }) => {
  const { t } = useTranslation();

  const errorMessageKey = getErrorMessageKey(errorMessageCode);

  if (errorMessageKey) {
    return (
      <NotificationBox
        isAlert={true}
        text={<p dangerouslySetInnerHTML={{ __html: t(`messages.${errorMessageKey}`) }}></p>}
        variant="error"
      />
    );
  }

  return null;
};

const DigitalCoreException: React.FC<Props> = ({ errorMessageCode }) => {
  return (
    <I18nProvider dictionary={locale => import(`./content/${locale}.json`)}>
      <DigitalCoreExceptionComponent errorMessageCode={errorMessageCode} />
    </I18nProvider>
  );
};

export default DigitalCoreException;
