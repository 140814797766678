import React from 'react';

import ContentLayout from '@layouts/native/content';
import ContentV2Layout from '@layouts/native/contentV2';
import DashboardLayout from '@layouts/native/dashboard';

export const SitecoreLayouts: Record<string, JSX.Element> = {
  '3d216140-4fae-4054-a4f8-e6b4398fdd18': <ContentLayout backgroundColor={'backgroundPrimary'} />,
  'c67edc63-278a-43bf-9812-8ff8a2a7dea1': <ContentLayout backgroundColor={'backgroundSecondary'} />,
  '9f7018ad-a37a-49aa-ba6f-103f201d19fd': <ContentV2Layout backgroundColor={'backgroundPrimary'} />,
  '7108c6ed-54b4-4633-b619-7027c9c7ef55': (
    <DashboardLayout backgroundColor={'backgroundSecondary'} backgroundColorHeader={'backgroundVarFour'} />
  ),
};
