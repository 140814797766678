import { FC, useContext, useEffect } from 'react';

import { useTranslation } from '@i18n';
import { Box, Button, NotificationBox, Stack, Stretch } from '@sparky';
import { useTracking } from '@tracking';

import { PrivacyConsentMachine, PrivacyConsentMachineActions } from './privacyConsentMachine';
import TrackingEventsNames from './tracking';

type Props = {
  onClickCancel?: () => void;
};

const ConsentFailed: FC<Props> = ({ onClickCancel }) => {
  const { send } = useContext(PrivacyConsentMachine);
  const { t } = useTranslation();
  const { trackError } = useTracking();

  useEffect(() => {
    trackError({ message: TrackingEventsNames.PrivacyConsentFailed });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stretch>
      <Stack alignY="justify">
        <NotificationBox
          isAlert
          title={t('consentFailed.notification.title')}
          text={t('consentFailed.notification.text')}
          variant="error"
        />

        <Box paddingBottom="6">
          <Stretch>
            <Stack alignY="justify" gap="4">
              <Button onClick={() => send({ type: PrivacyConsentMachineActions.TO_CONSENT })}>
                {t('consentFailed.button')}
              </Button>
              {onClickCancel && (
                <Button action="secondary" onClick={onClickCancel}>
                  {t('consentFailed.cancelButton')}
                </Button>
              )}
            </Stack>
          </Stretch>
        </Box>
      </Stack>
    </Stretch>
  );
};

export default ConsentFailed;
