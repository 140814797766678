import { FC, useContext } from 'react';

import { useTranslation } from '@i18n';
import { Box, Button, Heading, NotificationBox, Stack, Stretch, Text, TextLink } from '@sparky';

import { PrivacyConsentMachine, PrivacyConsentMachineActions } from './privacyConsentMachine';

interface Props {
  isLoading?: boolean;
}

const PrivacyConsentRevoked: FC<Props> = ({ isLoading }) => {
  const { send } = useContext(PrivacyConsentMachine);
  const { t } = useTranslation();

  return (
    <Stretch>
      <Stack alignY="justify">
        <Stack gap="10">
          <NotificationBox
            isAlert
            variant="success"
            text={t('privacyConsentRevoked.notificationTitle')}></NotificationBox>
          <Stack gap="6">
            <Stack gap="2">
              <Heading as="h1" size="S">
                {t('privacyConsentRevoked.title')}
              </Heading>
              <Text size="BodyM">{t('privacyConsentRevoked.description')}</Text>
            </Stack>
            <Stack gap="2">
              <Text weight="bold">{t('conditions.description')}</Text>
              <Text>{t('conditions.privacy.protect')}</Text>
              <Text>
                {t('conditions.privacy.approveConditions')}
                <TextLink onClick={() => send({ type: PrivacyConsentMachineActions.TO_CONDITIONS_CONTENT })}>
                  {t('conditions.privacy.conditionsLink')}
                </TextLink>
                {t('conditions.privacy.personalData')}
                <TextLink onClick={() => send({ type: PrivacyConsentMachineActions.TO_PRIVACY_STATEMENT_CONTENT })}>
                  {t('conditions.privacy.statementLink')}
                </TextLink>
                .
              </Text>
            </Stack>
          </Stack>
        </Stack>

        <Box paddingBottom="6">
          <Stretch>
            <Button
              isLoading={isLoading}
              onClick={() => send({ type: PrivacyConsentMachineActions.AGREE_CONSENT_FROM_REVOKE })}>
              {t('conditions.button')}
            </Button>
          </Stretch>
        </Box>
      </Stack>
    </Stretch>
  );
};

export default PrivacyConsentRevoked;
