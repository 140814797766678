import { FC, useEffect, useMemo, useRef } from 'react';

import { useActor, useInterpret } from '@xstate/react';

import { LoadingPage } from '@custom-components/native/LoadingPage';
import { AuthenticationContext } from '@dc/provider';
import useDC from '@dc/useDC';
import { I18nProvider, useTranslation } from '@i18n';
import { Stretch } from '@sparky';
import { TrackingProvider, useTracking } from '@tracking';

import Conditions from './Conditions';
import ConditionsContent from './ConditionsContent/ConditionsContent';
import ConsentFailed from './ConsentFailed';
import {
  CustomerContext,
  PrivacyConsentMachine,
  privacyConsentMachine,
  PrivacyConsentMachineState,
  PrivacyConsentMachineStates,
  RevokePrivacyConsentMachineStates,
} from './privacyConsentMachine';
import PrivacyConsentRevoked from './PrivacyConsentRevoked';
import PrivacyStatementContent from './PrivacyStatementContent';
import TrackingEventsNames from './tracking';
import BasicLayout from '../../../layouts/BasicLayout';

type Props = {
  children: React.ReactElement;
  onClickCancel?: () => void;
};

const PrivacyConsentFlowWrapper: FC<Props> = ({ children, onClickCancel }) => {
  const authenticationContext = useDC();

  if (!authenticationContext.customerId || !authenticationContext.accountId) {
    return <LoadingPage isLoading />;
  }
  return (
    <PrivacyConsentFlow authenticationContext={authenticationContext} onClickCancel={onClickCancel}>
      {children}
    </PrivacyConsentFlow>
  );
};

interface FlowProps extends Props {
  authenticationContext: AuthenticationContext;
}

const PrivacyConsentFlow: FC<FlowProps> = ({ children, onClickCancel, authenticationContext }) => {
  const { customerId, accountId, businessUnit, label } = authenticationContext;
  const machine = useMemo(
    () => privacyConsentMachine({ customerId, accountId, businessUnit, label } as CustomerContext),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const service = useInterpret(machine);
  const [state] = useActor(service);
  const { send } = service;

  return (
    <PrivacyConsentMachine.Provider value={{ state, send }}>
      <TrackingProvider scope={TrackingEventsNames.PrivacyConsent}>
        <I18nProvider dictionary={locale => import(`./content/${businessUnit.toLowerCase()}/${label}/${locale}.json`)}>
          <Stretch>
            <PrivacyConsentFlowStepComponent onClickCancel={onClickCancel} state={state}>
              {children}
            </PrivacyConsentFlowStepComponent>
          </Stretch>
        </I18nProvider>
      </TrackingProvider>
    </PrivacyConsentMachine.Provider>
  );
};

const PrivacyConsentFlowStepComponent: FC<{
  state: PrivacyConsentMachineState;
  children: React.ReactElement;
  onClickCancel?: () => void;
}> = ({ state, children, onClickCancel }) => {
  const { t } = useTranslation();
  const { trackFunnelCompleted, trackFunnelStart, trackPageView, resetFunnel } = useTracking();
  const funnelStarted = useRef(false);

  useEffect(() => {
    if (funnelStarted.current === false && state.matches(PrivacyConsentMachineStates.PRIVACY_CONSENT)) {
      trackPageView({ pageName: TrackingEventsNames.PrivacyConsent, previousPage: '' });
      trackFunnelStart({ step: TrackingEventsNames.PrivacyConsentStart, channel: 'app' });
      funnelStarted.current = true;
    }

    if (funnelStarted.current && state.matches(PrivacyConsentMachineStates.CONSENT_SUCCESS)) {
      trackFunnelCompleted({ step: TrackingEventsNames.PrivacyConsentComplete, channel: 'app' });
      resetFunnel();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  switch (true) {
    case state.matches(PrivacyConsentMachineStates.CHECK_CONSENT):
      return <LoadingPage isLoading text={t('privacyConsentLoading')} />;
    case state.matches(PrivacyConsentMachineStates.CONDITIONS_CONTENT):
      return (
        <BasicLayout>
          <ConditionsContent />
        </BasicLayout>
      );
    case state.matches(PrivacyConsentMachineStates.PRIVACY_STATEMENT_CONTENT):
      return (
        <BasicLayout>
          <PrivacyStatementContent />
        </BasicLayout>
      );
    case state.matches(PrivacyConsentMachineStates.GIVE_CONSENT):
    case state.matches(PrivacyConsentMachineStates.PRIVACY_CONSENT):
      return (
        <BasicLayout>
          <Conditions isLoading={state.matches(PrivacyConsentMachineStates.GIVE_CONSENT)} />
        </BasicLayout>
      );

    case state.matches(PrivacyConsentMachineStates.GIVE_CONSENT_FROM_REVOKE):
    case state.matches(PrivacyConsentMachineStates.PRIVACY_CONSENT_REVOKED):
      return (
        <BasicLayout>
          <PrivacyConsentRevoked isLoading={state.matches(PrivacyConsentMachineStates.GIVE_CONSENT_FROM_REVOKE)} />
        </BasicLayout>
      );
    case state.matches(PrivacyConsentMachineStates.CONSENT_FAILED):
      return (
        <BasicLayout>
          <ConsentFailed onClickCancel={onClickCancel} />
        </BasicLayout>
      );
    case state.matches({ [PrivacyConsentMachineStates.CONSENT_SUCCESS]: [RevokePrivacyConsentMachineStates.IDLE] }):
    case state.matches({
      [PrivacyConsentMachineStates.CONSENT_SUCCESS]: [RevokePrivacyConsentMachineStates.REVOKE_CONSENT],
    }):
    case state.matches({
      [PrivacyConsentMachineStates.CONSENT_SUCCESS]: [RevokePrivacyConsentMachineStates.REVOKE_CONSENT_FAILED],
    }):
      return children;
    default:
      return null;
  }
};

export default PrivacyConsentFlowWrapper;
