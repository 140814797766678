// eslint-disable-next-line @nx/enforce-module-boundaries
import dynamic from 'next/dynamic';

export default {
  /* APPS */
  Registration: dynamic(
    () => import('@apps/accessmanagement/be/eneco/native/src/components/Registration/Registration'),
  ),
  AppLandingPage: dynamic(
    () => import('@apps/accessmanagement/be/eneco/native/src/components/AppLandingPage/AppLandingPage'),
  ),
  AppVersion: dynamic(() => import('@apps/profile/be/eneco/native/src/AppInfo/AppInfo')),
  CustomerDetailsHeader: dynamic(
    () => import('@apps/profile/be/eneco/native/src/CustomerDetailsHeader/CustomerDetailsHeader'),
  ),
  ShareLocation: dynamic(() => import('@apps/profile/be/eneco/native/src/ShareLocation/ShareLocation')),
  SignOutButton: dynamic(() => import('@apps/profile/be/eneco/native/src/SignOutButton/SignOutButton')),
  DashboardServiceError: dynamic(
    () => import('@apps/dashboard/be/eneco/native/src/components/DashboardServiceError/DashboardServiceError'),
  ),

  EnergyProfileCard: dynamic(
    () => import('@apps/dashboard/be/eneco/native/src/components/EnergyProfileCard/EnergyProfileCard'),
  ),
  MonthSummaryCard: dynamic(
    () => import('@apps/dashboard/be/eneco/native/src/components/MonthSummaryCard/MonthSummaryCard'),
  ),
  MotivationCard: dynamic(() => import('@apps/dashboard/be/eneco/native/src/components/MotivationCard/MotivationCard')),
  DashboardHeader: dynamic(
    () => import('@apps/dashboard/be/eneco/native/src/components/DashboardHeader/DashboardHeader'),
  ),
  UsageCards: dynamic(() => import('@apps/dashboard/be/eneco/native/src/components/UsageCards/UsageCards')),
  AwaitingFluviusConfirmation: dynamic(
    () =>
      import('@apps/mandate/be/eneco/native/src/components/AwaitingFluviusConfirmation/AwaitingFluviusConfirmation'),
  ),
  FluviusOnboardingButton: dynamic(
    () => import('@apps/mandate/be/eneco/native/src/components/FluviusOnboardingButton/FluviusOnboardingButton'),
  ),
  MandateStatus: dynamic(() => import('@apps/mandate/be/eneco/native/src/components/MandateStatus/MandateStatus')),
  MandateWarning: dynamic(() => import('@apps/mandate/be/eneco/native/src/components/MandateWarning/MandateWarning')),
  NativeUsage: dynamic(() => import('@apps/usage/be/eneco/native/src/components/UsageOverview/UsageOverview')),
  EnergyProfile: dynamic(() => import('@apps/energyprofile/be/eneco/native/src/EnergyProfileFlow')),
  HomeProfileInsightsApp: dynamic(() => import('@apps/energyprofile/be/eneco/native/src/EnergyProfileOverview')),
  RevokePrivacy: dynamic(() => import('@apps/profile/be/eneco/native/src/RevokePrivacy/RevokePrivacy')),
  PasswordDetailsForm: dynamic(
    () => import('@apps/profile/shared/multilabel/src/PasswordDetailsForm/PasswordDetailsForm'),
  ),
  UsernameDetailsForm: dynamic(
    () => import('@apps/profile/shared/multilabel/src/UsernameDetailsForm/UsernameDetailsForm'),
  ),
  RemoveAccount: dynamic(() => import('@apps/profile/be/eneco/native/src/RemoveAccount/RemoveAccount')),
  FeatureContentCard: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/FeatureContentCard/FeatureContentCard'),
  ),
  SmartChargingDetail: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingDetail/SmartChargingDetail'),
  ),
  SmartChargingCompatibilityCheck: dynamic(
    () =>
      import(
        '@apps/hems/be/eneco/native/src/components/SmartChargingCompatibiltyCheck/SmartChargingCompatibilityCheck'
      ),
  ),
  SmartChargingCompatibilityCheckResult: dynamic(
    () =>
      import(
        '@apps/hems/be/eneco/native/src/components/SmartChargingCompatibiltyCheck/SmartChargingCompatibilityCheckResult'
      ),
  ),
  SmartChargingConsentButton: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingConsentButton/SmartChargingConsentButton'),
  ),
  SmartChargingSessionDetails: dynamic(
    () => import('@apps/hems/be/eneco/native/src/components/SmartChargingSessionDetails/SmartChargingSessionDetails'),
  ),

  /* SITECORE COMPONENTS */
  ButtonLink: dynamic(() => import('@sitecore/components/content/ButtonLink/ButtonLink')),
  Image: dynamic(() => import('@sitecore/components/content/Image/Image')),
  RichText: dynamic(() => import('@sitecore/components/content/RichText/RichText')),
  NotificationBox: dynamic(() => import('@sitecore/components/content/NotificationBox/NotificationBox')),
  Accordion: dynamic(() => import('@sitecore/components/content/Accordion/Accordion')),
  TitleTextCTA: dynamic(() => import('@sitecore/components/content/TitleTextCTA/TitleTextCTA')),
  Checklist: dynamic(() => import('@sitecore/components/content/Checklist/Checklist')),
  StepExplainer: dynamic(() => import('@sitecore/components/content/StepExplainerVertical/StepExplainerVertical')),

  /* NATIVE SITECORE COMPONENTS */
  BottomNavigationsButtons: dynamic(
    () => import('@sitecore/native-components/content/HemsBottomNavButtons/HemsBottomNavButtons'),
  ),
  BottomMenu: dynamic(() => import('@sitecore/native-components/content/BottomMenu/BottomMenu')),
  HEMSMinimalNavigationBar: dynamic(
    () => import('@sitecore/native-components/content/HemsMinimalNavigationBar/HemsMinimalNavigationBar'),
  ),
  TopBar: dynamic(() => import('@sitecore/native-components/content/TopBar/TopBar')),
  IconLink: dynamic(() => import('@sitecore/native-components/content/IconLink/IconLink')),
  PageTitle: dynamic(() => import('@sitecore/native-components/content/PageTitle/PageTitle')),
  TopNavigation: dynamic(() => import('@sitecore/native-components/content/TopNavigation/TopNavigation')),
  RealtimeContent: dynamic(() => import('@sitecore/native-components/content/RealtimeContent/RealtimeContent')),
  Bleed: dynamic(() => import('@sitecore/native-components/content/Bleed/Bleed')),
};
