import LoginRequired from '@native-auth/layout/LoginRequired';
import { getAuthService } from '@native-auth/service';
import FluviusMandateWrapper from '@native-components/components/wrappers/Fluvius/FluviusMandateWrapper';
import PrivacyConsent from '@native-components/components/wrappers/PrivacyConsent/PrivacyConsent';
import ProfileProvider from '@native-components/components/wrappers/ProfileProvider/ProfileProvider';

import { DashboardWrapper } from './DashboardWrapper';

export const MainWrapper = ({ children }) => (
  <LoginRequired>
    <PrivacyConsent
      onClickCancel={() => {
        getAuthService().signOut();
      }}>
      <ProfileProvider>
        <FluviusMandateWrapper>
          <DashboardWrapper>{children}</DashboardWrapper>
        </FluviusMandateWrapper>
      </ProfileProvider>
    </PrivacyConsent>
  </LoginRequired>
);
