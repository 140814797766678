import { CapacitorHttp } from '@capacitor/core';

// TODO Error handling and logging
// import logger from '@common/log';
import { sanitizePath } from '@common/path';
import startMockWorker from '@mocks/worker';

import { host, key, site, jss, jssPlaceholder } from './config';
import { BrowserClient, ClientResponse, BrowserClientPlaceholder } from './types';

startMockWorker();

const headers: HeadersInit = Object.freeze({
  'content-type': 'application/json',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchPlaceholder = async ({ path, locale, placeholderName }: BrowserClientPlaceholder): Promise<any> => {
  const item = sanitizePath(path);
  const url = new URL(jssPlaceholder, host);
  url.search = new URLSearchParams({
    item,
    sc_lang: locale,
    sc_apikey: key,
    sc_site: site,
    placeholderName,
  }).toString();
  const response = await CapacitorHttp.request({
    url: url.href,
    method: 'GET',
    headers,
  });
  return { layoutData: response.data, statusCode: response.status };
};

const client = async ({ path, locale }: BrowserClient): Promise<ClientResponse> => {
  const item = sanitizePath(path);
  const url = new URL(jss, host);
  url.search = new URLSearchParams({ item, sc_lang: locale, sc_apikey: key, sc_site: site }).toString();

  const response = await CapacitorHttp.request({
    url: url.href,
    method: 'GET',
    headers,
  });

  return { layoutData: response.data, statusCode: response.status };
};

export { client, fetchPlaceholder };
