import { styled } from '@sparky/stitches';

export const SafeArea = styled('div', {
  height: '100%',
  variants: {
    top: {
      true: {
        paddingTop: 'calc($1 + max($6, env(safe-area-inset-top)))',
      },
    },
    bottom: {
      true: {
        paddingBottom: 'max($6, env(safe-area-inset-bottom))',
      },
    },
  },
});

export default SafeArea;
