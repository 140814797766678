import { FC } from 'react';

import { NaturalScrollingArea } from '@custom-components/native/NaturalScrollingArea';
import SafeArea from '@custom-components/native/SafeArea';
import { Placeholder } from '@sitecore/common';
import { Box, Stack, Stretch } from '@sparky';

type BoxTypes = React.ComponentProps<typeof Box>;

const Layout: FC<{
  backgroundColor: BoxTypes['backgroundColor'];
}> = ({ backgroundColor }) => {
  // Prevent bottom padding negation. The flexbox container doesn't respect the bottom padding of the box component,
  // since the contentent sits inside the scrollable area. To make sure, that we have distance between the jss-main or jss-main-bottom and the bottom of the screen,
  // we add an empty component that is eaqual to the desired padding. The better solution would be to add the :after pseudo element to the Stack component, but since it's
  // an edge case, we decided to go with the simpler solution.
  const bottomPadding = <Box padding={3}> </Box>;
  return (
    <>
      <Placeholder name="jss-top-fixed" />

      <Stretch height>
        <Box backgroundColor={backgroundColor}>
          <Stretch height>
            <Stack alignY="justify">
              <Stretch>
                <NaturalScrollingArea>
                  <Stretch>
                    <Box paddingX="6">
                      <SafeArea top>
                        <Stretch>
                          <Stack>
                            <Stack.Item grow>
                              <Box>
                                <Stretch>
                                  <Stack gap="6">
                                    <Placeholder name="jss-main" />
                                  </Stack>
                                </Stretch>
                              </Box>
                            </Stack.Item>
                            <Placeholder name="jss-main-bottom" />
                            {bottomPadding}
                          </Stack>
                        </Stretch>
                      </SafeArea>
                    </Box>
                  </Stretch>
                </NaturalScrollingArea>
              </Stretch>
              <Placeholder name="jss-bottom-fixed" />
            </Stack>
          </Stretch>
        </Box>
      </Stretch>
    </>
  );
};
export default Layout;
