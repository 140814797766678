import { FC, useEffect } from 'react';

import { useGlobalLoader } from './GlobalLoader';
import Loader from './Loader';
interface Props {
  isLoading?: boolean;
  disableGlobalLoading?: boolean;
  text?: string;
}

export const LoadingPage: FC<Props> = ({ isLoading, text, disableGlobalLoading = false }) => {
  const globalLoader = useGlobalLoader();

  useEffect(() => {
    // Always set global loading state to false when unmounted
    return () => {
      if (!globalLoader || disableGlobalLoading) {
        return;
      }
      globalLoader.setLoading(false, text);
    };
  }, [globalLoader, disableGlobalLoading, text]);

  useEffect(() => {
    if (!globalLoader || disableGlobalLoading) {
      return;
    }
    globalLoader.setLoading(!!isLoading, text);
  }, [globalLoader, isLoading, text, disableGlobalLoading]);

  if (isLoading && !globalLoader) {
    return <Loader text={text} />;
  }

  return null;
};
