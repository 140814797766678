import { FC, useContext } from 'react';

import { useTranslation } from '@i18n';
import { Heading, Stack, Text, TextLink } from '@sparky';
import { ChevronLeftIcon } from '@sparky/icons';

import Aansprakelijkheid from './Aansprakelijkheid';
import Algemeen from './Algemeen';
import Definities from './Definities';
import DuurEnbeëindiging from './DuurEnbeëindiging';
import GarantiesVrijwaringOvermacht from './GarantiesVrijwaringOvermacht';
import Gebruik from './Gebruik';
import Intellectuele from './Intellectuele';
import Price from './Price';
import Privacy from './Privacy';
import Toepasselijk from './Toepasselijk';
import { PrivacyConsentMachine, PrivacyConsentMachineActions } from '../privacyConsentMachine';

const ConditionsContent: FC = () => {
  const { t } = useTranslation();
  const { send } = useContext(PrivacyConsentMachine);

  return (
    <Stack gap="6">
      <Stack gap="5">
        <TextLink
          onClick={() => send({ type: PrivacyConsentMachineActions.TO_PRIVACY_CONSENT })}
          emphasis="medium"
          leftIcon={<ChevronLeftIcon />}>
          {t('conditionsContent.backButtonText')}
        </TextLink>
        <Heading as="h1" size="S">
          {t('conditionsContent.title')}
        </Heading>
        <Text>{t('conditionsContent.description')}</Text>
      </Stack>
      <Definities />
      <Gebruik />
      <Algemeen />
      <Intellectuele />
      <Price />
      <Privacy />
      <Aansprakelijkheid />
      <DuurEnbeëindiging />
      <GarantiesVrijwaringOvermacht />
      <Toepasselijk />
      <Stack gap="1">
        <Text weight="bold">{t('conditionsContent.copyright.title')}</Text>
        <Text>{t('conditionsContent.copyright.description')}</Text>
      </Stack>
    </Stack>
  );
};

export default ConditionsContent;
