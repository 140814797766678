import { FC } from 'react';

import { NaturalScrollingArea } from '@custom-components/native/NaturalScrollingArea';
import { Placeholder } from '@sitecore/common';
import { Box, Stack, Stretch } from '@sparky';

const Layout: FC = () => {
  return (
    <>
      <Placeholder name="jss-top-fixed" />

      <Stretch height>
        <Box>
          <Stretch height>
            <Stack alignY="justify">
              <Stretch>
                <NaturalScrollingArea>
                  <Placeholder name="jss-main" />
                </NaturalScrollingArea>
              </Stretch>
              <Placeholder name="jss-bottom-fixed" />
            </Stack>
          </Stretch>
        </Box>
      </Stretch>
    </>
  );
};
export default Layout;
