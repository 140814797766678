import LoginRequired from '@native-auth/layout/LoginRequired';
import FluviusReturnWrapper from '@native-components/components/wrappers/Fluvius/FluviusReturnWrapper';
import ProfileProvider from '@native-components/components/wrappers/ProfileProvider/ProfileProvider';

export const FluviusReturnLoginWrapper = ({ children }) => (
  <LoginRequired>
    <ProfileProvider>
      <FluviusReturnWrapper>{children}</FluviusReturnWrapper>
    </ProfileProvider>
  </LoginRequired>
);
