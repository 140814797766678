import { createDCHook, createMutationHook, collapseDataFromCall, collapseParams } from '../client';
import {
  getEnergyProfile,
  storeEnergyProfile,
  getPrivacyConsent,
  givePrivacyConsent,
  revokePrivacyConsent,
  getMotivations,
  storeMotivations,
} from '../services/EnergyProfileService';

export const useEnergyProfileGetEnergyProfile = createDCHook(
  'getEnergyProfile',
  collapseDataFromCall(getEnergyProfile),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
  { maxAge: 15000 },
);

export const useEnergyProfileStoreEnergyProfile = createMutationHook(
  collapseParams(
    collapseParams(storeEnergyProfile, 'requestBody', 'data'),
    'data',
    'building',
    'houseHold',
    'appliances',
  ),
  {
    injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['building', 'houseHold', 'appliances'] },
    ],
  },
);

export const useEnergyProfileGetPrivacyConsent = createDCHook(
  'getPrivacyConsent',
  collapseDataFromCall(getPrivacyConsent),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
);

export const useEnergyProfileGivePrivacyConsent = createMutationHook(givePrivacyConsent, {
  injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
  flattenData: false,
});

export const useEnergyProfileRevokePrivacyConsent = createMutationHook(revokePrivacyConsent, {
  injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
  flattenData: false,
});

export const useEnergyProfileGetMotivations = createDCHook('getMotivations', collapseDataFromCall(getMotivations), {
  injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
  flattenData: true,
});

export const useEnergyProfileStoreMotivations = createMutationHook(
  collapseParams(collapseParams(storeMotivations, 'requestBody', 'data'), 'data', 'motivations'),
  {
    injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['motivations'] },
    ],
  },
);
