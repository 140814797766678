import React, { FC, PropsWithChildren, useEffect } from 'react';

import { useApplication } from '@common/application';
import inAppBrowser from '@native-in-app-browser/index';

export function getItemFromUrl() {
  if (typeof document === 'undefined') {
    return;
  }
  const url = new URL(document.URL);
  const itemParam = url.searchParams.get('item');
  if (itemParam) {
    return decodeURIComponent(itemParam);
  }
  return '/'; // return root when ?item= is not set
}

const FluviusReturnWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { searchParams } = useApplication();
  const mandateReturnStatus = searchParams.get('mandatestatus') ?? '';

  useEffect(() => {
    // If the mandate status is failed, start over the onboarding flow
    if (mandateReturnStatus === 'failed') {
      window.location.assign('/?item=%2Ffluviusonboarding');
    }

    inAppBrowser.closeWindow();
  }, [mandateReturnStatus]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default FluviusReturnWrapper;
