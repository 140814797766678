import { useEffect, useState } from 'react';

import { Preferences } from '@capacitor/preferences';
import { AppPreferences } from '@common/preferences';
import { LoadingPage } from '@custom-components/native/LoadingPage';
import { useRouter } from '@dxp-next';

const { LAST_LOGIN } = AppPreferences;

export const LoginStateRouting = ({ children }) => {
  const router = useRouter();
  const [userHasLoggedIn, setUserHasLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    Preferences.get({ key: LAST_LOGIN }).then(item => {
      setUserHasLoggedIn(!!item.value);
    });
  }, []);

  if (userHasLoggedIn === null) {
    return <LoadingPage isLoading />;
  }

  // Redirect to dashboard if user has logged in before
  // Note: the user might not be logged in at the moment, then the user will see a login screen first
  if (userHasLoggedIn) {
    router.push('/?item=%2Fdashboard');
  }

  // Otherwise: let user choose whether to login or register
  return children;
};
