import { FC } from 'react';

import { useTranslation } from '@i18n';
import { Stack, Text } from '@sparky';

const Toepasselijk: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack gap="4">
      <Stack gap="1">
        <Text weight="bold">{t('conditionsContent.toepasselijk.title')}</Text>
      </Stack>
      <Stack gap="1">
        <Text>{t('conditionsContent.toepasselijk.items.1')}</Text>
      </Stack>
      <Stack gap="1">
        <Text>{t('conditionsContent.toepasselijk.items.2')}</Text>
      </Stack>
    </Stack>
  );
};

export default Toepasselijk;
