import { useContext, VFC } from 'react';

import { useTranslation } from '@i18n';
import { Box, Button, Heading, Stack, Stretch, Text, TextLink } from '@sparky';

import { PrivacyConsentMachine, PrivacyConsentMachineActions } from './privacyConsentMachine';

interface Props {
  isLoading?: boolean;
}

const Conditions: VFC<Props> = ({ isLoading }) => {
  const { send } = useContext(PrivacyConsentMachine);
  const { t } = useTranslation();

  return (
    <Stretch>
      <Stack alignY="justify">
        <Stack gap="6">
          <Heading as="h1" size="S">
            {t('conditions.title')}
          </Heading>
          <Stack gap="2">
            <Text weight="bold">{t('conditions.description')}</Text>
            <Text>
              {t('conditions.privacy.protect')}
              <TextLink onClick={() => send({ type: PrivacyConsentMachineActions.TO_CONDITIONS_CONTENT })}>
                {t('conditions.privacy.conditionsLink')}
              </TextLink>
              {t('conditions.privacy.protectExtra')}
            </Text>
            <Text>
              {t('conditions.privacy.approveConditions')}
              <TextLink onClick={() => send({ type: PrivacyConsentMachineActions.TO_PRIVACY_STATEMENT_CONTENT })}>
                {t('conditions.privacy.statementLink')}
              </TextLink>
              .
            </Text>
          </Stack>
        </Stack>

        <Box paddingBottom="6">
          <Stretch>
            <Button isLoading={isLoading} onClick={() => send({ type: PrivacyConsentMachineActions.AGREE_CONSENT })}>
              {t('conditions.button')}
            </Button>
          </Stretch>
        </Box>
      </Stack>
    </Stretch>
  );
};

export default Conditions;
