import React, { FC, useEffect } from 'react';

import { LoadingPage } from '@custom-components/native/LoadingPage';
import useDC from '@dc/useDC';
import { useRouter } from '@dxp-next';
import { I18nProvider, useTranslation } from '@i18n';

import { useAuth } from '../hooks/auth';
import { getAuthService } from '../service';
import authBrowser from '../service/AuthBrowser';

const LoginRequired: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { label, businessUnit } = useDC();
  return (
    <I18nProvider
      dictionary={locale => import(`./content/${businessUnit.toLowerCase()}/${label.toLowerCase()}/${locale}.json`)}>
      <LoginRequiredComponent>{children}</LoginRequiredComponent>
    </I18nProvider>
  );
};

const LoginRequiredComponent: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { isLoaded, isAuthenticated, isRefreshing } = useAuth();
  const authService = getAuthService();

  // Redirect to login
  useEffect(() => {
    if (!isAuthenticated && isLoaded) {
      authService.signIn();
    }
  }, [authService, isAuthenticated, isLoaded, router]);

  useEffect(() => {
    if (isAuthenticated) {
      authBrowser.closeWindow();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <LoadingPage isLoading text={t('loading')} />;
  }

  if (isRefreshing) {
    return <LoadingPage isLoading text={t('refreshing')} />;
  }

  return <>{children}</>;
};

export default LoginRequired;
