export enum ValidationExceptions {
  DEFAULT = 'DEFAULT',
  NOT_FLANDERS = 'DCP-BE|006181ff-9676-4634-94af-5c354414fb4f',
  NO_DIGITAL_METER = 'DCP-BE|75e16dd1-6507-491a-895a-7490a93b6b75',
  NO_R1_R3_METERINGREGIME = 'DCP-BE|4daacb52-4c55-4e57-b7bc-4e1b35abd48d',
  CONTRACT_TERMINATED = 'DCP-BE|bdc08358-957f-4102-be03-6b1746541bb7',
  CONTRACT_CANCELED = 'DCP-BE|29d7f7d5-bb92-476f-9c73-4f83174430f6',
  CONTRACT_REQUESTED = 'DCP-BE|2628d47f-a4ca-491b-9d2d-89b2ac57a699',
  CONTRACT_RENEWED = 'DCP-BE|14659a2b-c9fa-4193-8407-d36df20e49b5',
  CONTRACT_PENDING = 'DCP-BE|14fc7be6-920e-46c5-9a63-343cc03905dd',
  CONTRACT_SIGNED = 'DCP-BE|3424752d-1f91-4f74-916f-698ec4b772a0',
  CONTRACT_STOPPED = 'DCP-BE|a069ea95-e3be-4b07-9be6-f55506b0ae34',
  CONTRACT_CANCEL_REQUESTED = 'DCP-BE|b40cb671-4fe2-493e-bab4-51aea0b3180d',
}
