export function getItemFromUrl() {
  if (typeof document === 'undefined') {
    return;
  }
  const url = new URL(document.URL);
  const itemParam = url.searchParams.get('item');
  if (itemParam) {
    return decodeURIComponent(itemParam);
  }
  return '/'; // return root when ?item= is not set
}
