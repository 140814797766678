import { FC } from 'react';

import { NaturalScrollingArea } from '@custom-components/native/NaturalScrollingArea';
import SafeArea from '@custom-components/native/SafeArea';
import { Placeholder } from '@sitecore/common';
import { Bleed, Box, Grid, Stack, Stretch } from '@sparky';

type BoxTypes = React.ComponentProps<typeof Box>;

const Layout: FC<{
  backgroundColor: BoxTypes['backgroundColor'];
  backgroundColorHeader: BoxTypes['backgroundColor'];
}> = ({ backgroundColor, backgroundColorHeader }) => {
  return (
    <>
      <Placeholder name="jss-top-fixed" />

      <Stretch height>
        <Box>
          <Stretch height>
            <Stack alignY="justify">
              <Stretch>
                <NaturalScrollingArea>
                  <Stretch>
                    <Box backgroundColor={backgroundColor}>
                      <Stretch>
                        <Grid gridTemplateRows="1fr 2fr">
                          <Grid.Item>
                            <Stretch>
                              <Box
                                backgroundColor={backgroundColorHeader}
                                paddingX="6"
                                paddingTop="2"
                                paddingBottom="24">
                                <SafeArea top>
                                  <Stack gap="8">
                                    <Placeholder name="jss-header" />
                                  </Stack>
                                </SafeArea>
                              </Box>
                            </Stretch>
                          </Grid.Item>
                          <Grid.Item>
                            <Bleed top="16">
                              <Box paddingBottom="6" paddingX="6">
                                <Stack gap="6">
                                  <Placeholder name="jss-main" />
                                </Stack>
                              </Box>
                            </Bleed>
                          </Grid.Item>
                        </Grid>
                      </Stretch>
                    </Box>
                  </Stretch>
                </NaturalScrollingArea>
              </Stretch>
              <Placeholder name="jss-bottom-fixed" />
            </Stack>
          </Stretch>
        </Box>
      </Stretch>
    </>
  );
};
export default Layout;
