import { FC, PropsWithChildren } from 'react';

import { LoadingPage } from '@custom-components/native/LoadingPage';
import { ApiError } from '@dc/client/ApiError';
import { useCustomerGetCustomerProfile } from '@dc/hooks';
import useDC from '@dc/useDC';
import { I18nProvider, useTranslation } from '@i18n';
import { Box, NotificationBox } from '@sparky';

import DigitalCoreException from '../../DigitalCoreException/DigitalCoreException';

const ProfileProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { label, businessUnit } = useDC();

  return (
    <I18nProvider
      dictionary={locale => import(`./content/${businessUnit.toLowerCase()}/${label.toLowerCase()}/${locale}.json`)}>
      <ProfileProvider>{children}</ProfileProvider>
    </I18nProvider>
  );
};

const ProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t, isLoading } = useTranslation();
  const { error, isValidating }: { error: ApiError; isValidating: boolean } = useCustomerGetCustomerProfile();

  const errorMessageCode = error?.errors?.[0]?.innerErrors?.[0]?.code;

  if (isValidating || isLoading) {
    return <LoadingPage isLoading text={t('profileProvider.loading')} />;
  }

  if (error) {
    if (errorMessageCode) {
      return (
        <Box padding="6">
          <DigitalCoreException errorMessageCode={errorMessageCode} />
        </Box>
      );
    } else {
      return (
        <Box padding="6">
          <NotificationBox isAlert={true} text={t('profileProvider.message')} variant="error" />
        </Box>
      );
    }
  }

  return children;
};

export default ProfileProviderWrapper;
