import { useEffect } from 'react';

import { Preferences } from '@capacitor/preferences';
import { AppPreferences } from '@common/preferences';
import { LoadingPage } from '@custom-components/native/LoadingPage';
import { useEnergyProfileGetEnergyProfile } from '@dc/hooks';
import { useRouter } from '@dxp-next';

const { ENERGY_PROFILE_REQUESTED } = AppPreferences;

export const DashboardWrapper = ({ children }) => {
  const { push } = useRouter();
  const { data, isValidating } = useEnergyProfileGetEnergyProfile();

  useEffect(() => {
    if (!isValidating && !data?.profileIsStored) {
      Preferences.get({ key: ENERGY_PROFILE_REQUESTED }).then(({ value }) => {
        if (value !== 'true') {
          push(`/?item=%2Fenergyprofile&return=${encodeURIComponent(window.location.href)}&state=START`);
        }
      });
    }
  }, [data, isValidating, push]);

  if (isValidating) {
    return <LoadingPage isLoading />;
  }

  return children;
};
