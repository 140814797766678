import { createContext } from 'react';

import { Condition, createMachine, DoneInvokeEvent, InterpreterFrom, State } from 'xstate';

import { AuthenticationContext } from '@dc/provider';
import { getPrivacyConsent, givePrivacyConsent, revokePrivacyConsent } from '@dc/services';

type CustomerContextValues = Pick<
  NonNullable<AuthenticationContext>,
  'accountId' | 'businessUnit' | 'customerId' | 'label'
>;

export type CustomerContext = {
  [P in keyof CustomerContextValues]: NonNullable<CustomerContextValues[P]>;
};

export enum PrivacyConsentMachineStates {
  CHECK_CONSENT = 'CHECK_CONSENT',
  PRIVACY_CONSENT = 'PRIVACY_CONSENT',
  PRIVACY_CONSENT_REVOKED = 'PRIVACY_CONSENT_REVOKED',
  GIVE_CONSENT = 'GIVE_CONSENT',
  GIVE_CONSENT_FROM_REVOKE = 'GIVE_CONSENT_FROM_REVOKE',
  CONSENT_FAILED = 'CONSENT_FAILED',
  CONSENT_SUCCESS = 'CONSENT_SUCCESS',
  PRIVACY_STATEMENT_CONTENT = 'PRIVACY_STATEMENT_CONTENT',
  CONDITIONS_CONTENT = 'CONDITIONS_CONTENT',
}

type PrivacyConsentMachineService = InterpreterFrom<typeof privacyConsentMachine>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PrivacyConsentMachineState = State<any, Event, any, { value: any; context: any }, any>;

export enum PrivacyConsentMachineActions {
  AGREE_CONSENT = 'AGREE_CONSENT',
  AGREE_CONSENT_FROM_REVOKE = 'AGREE_CONSENT_FROM_REVOKE',
  TO_CONSENT = 'TO_CONSENT',
  TO_CONSENT_SUCCESS = 'TO_CONSENT_SUCCESS',
  TO_PRIVACY_STATEMENT_CONTENT = 'TO_PRIVACY_STATEMENT_CONTENT',
  TO_CONDITIONS_CONTENT = 'TO_CONDITIONS_CONTENT',
  TO_PRIVACY_CONSENT = 'TO_PRIVACY_CONSENT',
  TO_PRIVACY_CONSENT_REVOKED = 'TO_PRIVACY_CONSENT_REVOKED',
}

export enum RevokePrivacyConsentMachineStates {
  IDLE = 'IDLE',
  REVOKE_CONSENT = 'REVOKE_CONSENT',
  REVOKE_CONSENT_FAILED = 'REVOKE_CONSENT_FAILED',
  REVOKE_CONSENT_SUCCESS = 'REVOKE_CONSENT_SUCCESS',
}

export enum RevokePrivacyConsentMachineActions {
  TO_IDLE = 'TO_IDLE',
  TO_REVOKE_CONSENT = 'TO_REVOKE_CONSENT',
}

type Event =
  | { type: PrivacyConsentMachineActions.AGREE_CONSENT }
  | { type: PrivacyConsentMachineActions.AGREE_CONSENT_FROM_REVOKE }
  | { type: PrivacyConsentMachineActions.TO_CONSENT }
  | { type: PrivacyConsentMachineActions.TO_CONSENT_SUCCESS }
  | { type: PrivacyConsentMachineActions.TO_PRIVACY_CONSENT }
  | { type: PrivacyConsentMachineActions.TO_PRIVACY_CONSENT_REVOKED }
  | { type: PrivacyConsentMachineActions.TO_PRIVACY_STATEMENT_CONTENT }
  | { type: PrivacyConsentMachineActions.TO_CONDITIONS_CONTENT }
  | { type: RevokePrivacyConsentMachineActions.TO_IDLE }
  | { type: RevokePrivacyConsentMachineActions.TO_REVOKE_CONSENT };

const hasConsent: Condition<CustomerContext, DoneInvokeEvent<boolean>> = (context, event) => {
  return event.data;
};

export const privacyConsentMachine = ({ accountId, businessUnit, customerId, label }: CustomerContext) =>
  createMachine<CustomerContext, Event>(
    {
      predictableActionArguments: true,
      id: 'privacyConsentMachine',
      context: {
        accountId,
        businessUnit,
        customerId,
        label,
      },
      initial: PrivacyConsentMachineStates.CHECK_CONSENT,
      states: {
        [PrivacyConsentMachineStates.CHECK_CONSENT]: {
          invoke: {
            id: 'getPrivacyConsent',
            src: 'getPrivacyConsent',
            onDone: [
              {
                target: PrivacyConsentMachineStates.CONSENT_SUCCESS,
                cond: hasConsent,
              },
              {
                target: PrivacyConsentMachineStates.PRIVACY_CONSENT,
                cond: (context, event, meta) => !hasConsent(context, event, meta),
              },
            ],
            onError: {
              target: PrivacyConsentMachineStates.CONSENT_FAILED,
            },
          },
        },
        [PrivacyConsentMachineStates.CONSENT_FAILED]: {
          on: {
            [PrivacyConsentMachineActions.TO_CONSENT]: PrivacyConsentMachineStates.CHECK_CONSENT,
          },
        },
        [PrivacyConsentMachineStates.CONSENT_SUCCESS]: {
          initial: RevokePrivacyConsentMachineStates.IDLE,
          states: {
            [RevokePrivacyConsentMachineStates.IDLE]: {
              on: {
                [RevokePrivacyConsentMachineActions.TO_REVOKE_CONSENT]: {
                  target: [RevokePrivacyConsentMachineStates.REVOKE_CONSENT],
                },
              },
            },
            [RevokePrivacyConsentMachineStates.REVOKE_CONSENT]: {
              invoke: {
                src: 'revokePrivacyConsent',
                id: 'revokePrivacyConsent',
                onDone: [
                  {
                    target: [RevokePrivacyConsentMachineStates.REVOKE_CONSENT_SUCCESS],
                  },
                ],
                onError: [
                  {
                    target: [RevokePrivacyConsentMachineStates.REVOKE_CONSENT_FAILED],
                  },
                ],
              },
            },
            [RevokePrivacyConsentMachineStates.REVOKE_CONSENT_FAILED]: {
              on: {
                [RevokePrivacyConsentMachineActions.TO_IDLE]: {
                  target: [RevokePrivacyConsentMachineStates.IDLE],
                },
                [RevokePrivacyConsentMachineActions.TO_REVOKE_CONSENT]: {
                  target: [RevokePrivacyConsentMachineStates.REVOKE_CONSENT],
                },
              },
            },
            [RevokePrivacyConsentMachineStates.REVOKE_CONSENT_SUCCESS]: {
              type: 'final',
            },
          },
          onDone: {
            target: [PrivacyConsentMachineStates.PRIVACY_CONSENT_REVOKED],
          },
        },
        [PrivacyConsentMachineStates.CONDITIONS_CONTENT]: {
          on: {
            [PrivacyConsentMachineActions.TO_PRIVACY_CONSENT]: PrivacyConsentMachineStates.PRIVACY_CONSENT,
          },
        },
        [PrivacyConsentMachineStates.PRIVACY_STATEMENT_CONTENT]: {
          on: {
            [PrivacyConsentMachineActions.TO_PRIVACY_CONSENT]: PrivacyConsentMachineStates.PRIVACY_CONSENT,
          },
        },
        [PrivacyConsentMachineStates.PRIVACY_CONSENT]: {
          on: {
            [PrivacyConsentMachineActions.TO_CONDITIONS_CONTENT]: PrivacyConsentMachineStates.CONDITIONS_CONTENT,
            [PrivacyConsentMachineActions.TO_PRIVACY_STATEMENT_CONTENT]:
              PrivacyConsentMachineStates.PRIVACY_STATEMENT_CONTENT,
            [PrivacyConsentMachineActions.AGREE_CONSENT]: PrivacyConsentMachineStates.GIVE_CONSENT,
          },
        },
        [PrivacyConsentMachineStates.PRIVACY_CONSENT_REVOKED]: {
          on: {
            [PrivacyConsentMachineActions.TO_CONDITIONS_CONTENT]: PrivacyConsentMachineStates.CONDITIONS_CONTENT,
            [PrivacyConsentMachineActions.TO_PRIVACY_STATEMENT_CONTENT]:
              PrivacyConsentMachineStates.PRIVACY_STATEMENT_CONTENT,
            [PrivacyConsentMachineActions.AGREE_CONSENT_FROM_REVOKE]:
              PrivacyConsentMachineStates.GIVE_CONSENT_FROM_REVOKE,
          },
        },
        [PrivacyConsentMachineStates.GIVE_CONSENT_FROM_REVOKE]: {
          invoke: {
            id: 'givePrivacyConsent',
            src: 'givePrivacyConsent',
            onDone: {
              target: PrivacyConsentMachineStates.CONSENT_SUCCESS,
            },
            onError: {
              target: PrivacyConsentMachineStates.CONSENT_FAILED,
            },
          },
        },
        [PrivacyConsentMachineStates.GIVE_CONSENT]: {
          invoke: {
            id: 'givePrivacyConsent',
            src: 'givePrivacyConsent',
            onDone: {
              target: PrivacyConsentMachineStates.CONSENT_SUCCESS,
            },
            onError: {
              target: PrivacyConsentMachineStates.CONSENT_FAILED,
            },
          },
        },
      },
    },
    {
      services: {
        getPrivacyConsent: async ({ accountId, businessUnit, customerId, label }: CustomerContext) => {
          if (!accountId) throw new Error('Expected `accountId` to be defined');
          if (!businessUnit) throw new Error('Expected `businessUnit` to be defined');
          if (!customerId) throw new Error('Expected `customerId` to be defined');
          if (!label) throw new Error('Expected `label` to be defined');

          const response = await getPrivacyConsent({
            label,
            businessUnit,
            customerId,
            accountId,
          });
          return response?.data || false;
        },
        givePrivacyConsent: async ({ accountId, businessUnit, customerId, label }: CustomerContext) => {
          if (!accountId) throw new Error('Expected `accountId` to be defined');
          if (!businessUnit) throw new Error('Expected `businessUnit` to be defined');
          if (!customerId) throw new Error('Expected `customerId` to be defined');
          if (!label) throw new Error('Expected `label` to be defined');

          await givePrivacyConsent({
            label,
            businessUnit,
            customerId,
            accountId,
          });
        },
        revokePrivacyConsent: async ({ accountId, businessUnit, customerId, label }: CustomerContext) => {
          if (!accountId) throw new Error('Expected `accountId` to be defined');
          if (!businessUnit) throw new Error('Expected `businessUnit` to be defined');
          if (!customerId) throw new Error('Expected `customerId` to be defined');
          if (!label) throw new Error('Expected `label` to be defined');

          await revokePrivacyConsent({
            label,
            businessUnit,
            customerId,
            accountId,
          });
        },
      },
    },
  );

export const PrivacyConsentMachine = createContext(
  {} as { state: PrivacyConsentMachineState; send: PrivacyConsentMachineService['send'] },
);
